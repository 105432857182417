@font-face {
  font-family: 'FunnyAndCute';
  src: url(./fonts/FunnyAndCute/FunnyAndCute.otf);
}

.ant-modal-close {
  display: none;
}

.ant-modal-title {
  text-align: center;
}

tbody>tr>td>.indent-level-1~.anticon.anticon-caret-right {
  display: none;
}

tr>td:last-child {
  border-right: 1px solid #f5f5f5;
}

tr>td:first-child {
  border-left: 1px solid #f5f5f5;
}

.ant-table-thead>tr>.ant-table-cell {
  background-color: #045993 !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important
}


.ant-table-row.ant-table-row-level-0>td,
.ant-table-cell {
  font-weight: 400;
  font-size: 16px;
  color: #151515;
}

.ant-table-thead>tr>th {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #FFFFFF !important;
}

.ant-table-row.ant-table-row-level-1 .ant-checkbox-inner,
.ant-table-row.ant-table-row-level-1 .w-4.h-4.border {
  visibility: hidden;
}

tr:last-child {
  background-color: #F5FBFF !important;
}

tr:last-child .anticon.anticon-caret-right {
  visibility: hidden;
}

tr:last-child .ant-checkbox-inner {
  display: none;
}

tr:last-child>td:first-child {
  border-bottom-left-radius: 8px !important;
}

tr:last-child>td:last-child {
  border-bottom-right-radius: 8px !important;
}

.ant-table-cell.ant-table-cell-with-append {
  display: flex;
  align-items: center;
}

.custom-modal-no-rouder .ant-modal-content {
  padding: 0 !important;
  border-radius: 0 !important;
}

.p-0.custom-modal .ant-modal-content {
  padding: 0 !important;
}

.rouder-31 .ant-modal-content {
  border-radius: 31px;
}

.nsm7Bb-HzV7m-LgbsSe {
  border: none !important;
}

.ant-table-row.ant-table-row-level-1 td:nth-child(2) {
  color: #FFFFFF !important;
}

tr:last-child td:nth-child(2) {
  /* color: #f5fbff; */
}

.border-hidden-custom:last-child {
  border-bottom: none !important;
}

.custom-table .ant-checkbox-wrapper {
  visibility: hidden;
}

.container-slide-down {
  height: 54px;
  border-radius: 26px;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

.height-99 {
  height: 99px !important;
}

.button-slide-down {
  transition: all .7s ease-in-out;
  opacity: 0;
}

.button-slide-down-active {
  transition: all 1s ease-in-out;
  opacity: 1;
}

.fade-in {
  transition: all .5s ease-in-out;
  opacity: 1;
  /* display: block; */
  visibility: visible;
}

.fade-out {
  transition: all .5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  /* display: none; */
}

.shadow-cus {
  box-shadow: 0px 5px 14px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px 5px 14px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 5px 14px 4px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .boxShadow {
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.1)
  }
}

.shadow {
  box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.10);
}

.boxShadow-1 {
  box-shadow: 0px 2px 24px rgba(114, 132, 155, 0.18);
}

.nav.default-nav {
  background-color: transparent !important;
}

.wrapper {
  /* position: relative;
  overflow: hidden;
  width: 100%;
  height: 320px;
  cursor: pointer; */
}

#slide {
  top: 36%;
  position: absolute;
  left: 97.4vw;
  height: 300px;
  transition: 1s;
}

#slide:hover {
  transition: 1s;
  left: 0;
}

.ant-table-row.ant-table-row-level-0 .ant-table-cell .font-\[700\] {
  font-weight: 700;
}

.ant-table-row.ant-table-row-level-1 .ant-table-cell .font-\[700\] {
  font-weight: 500;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  /* border-bottom: none !important */
}

.custom-input {
  min-width: 250px;
  height: 36px;
  border: 1px solid #9f9f9f;
  border-radius: 10px;
  background-color: #FFFFFF;

  color: #202027;
  font-size: 15px;
  font-weight: 500;
}

.ant-select-selector {
  height: 36px !important;
  border-radius: 10px !important;
  background-color: unset !important;
  border: unset !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 3px 0px;
}

.ant-table-thead .ant-table-cell {
  padding: 12px 0px !important;
}

.ant-table-thead .ant-table-cell:last-child,
.ant-table-wrapper .ant-table-tbody tr td:last-child {
  padding-right: 12px !important;
}

.ant-table-row-level-1 .ant-table-cell {
  padding: 6px 0px !important;
}

.rounded-\[6px\] .ant-modal-content {
  border-radius: 6px !important;
  padding: 16px;
}

.nav.default-nav {
  display: none;
}

.ant-select-selection-item {
  line-height: 2.4 !important;
  color: #595959 !important;
  /* font-size: 18px !important; */
  /* color: #595959 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  */
}

input::-webkit-input-placeholder,
input:-ms-input-placeholder,
input::placeholder {
  font-size: 16px;
  font-weight: 500;
}


@media (max-width: 575px) {
  .ant-form-item-row {
    margin-bottom: 10px;
  }

  .ant-form-item .ant-form-item-label {
    flex: 0 0 35% !important;
    width: 70px !important;
  }

  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 97%;
    max-width: 100%
  }

  .tabActive {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 425px) {
  .custom-input {
    min-width: 49vw;
  }

  .ant-form-item .ant-form-item-label {
    flex: 0 0 47% !important;
    width: 70px !important;
  }
}

/* iphone 12 pro */
@media (max-width: 390px) {
  .custom-input {
    min-width: 45vw;
  }

  .so_phieu_bao .custom-input {
    min-width: 47vw;
    width: 97.9%;
  }

  .frame-items {
    min-height: calc(100vh + 160px);
  }
}

@media (max-width: 375px) {
  .so_phieu_bao .ant-form-item-label {
    flex: 0 0 37% !important;
  }

  .so_phieu_bao .custom-input {
    width: 64vw;
  }

  .custom-input {
    min-width: 46vw;
  }

  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media (max-width: 320px) {
  .ant-form-item-row {
    margin-bottom: 10px;
  }

  .ant-form-item .ant-form-item-label {
    flex: 0 0 100px !important;
    width: 50px !important;
  }

  .custom-input {
    min-width: calc(100vw - 166px)
  }

  .width-custom {
    width: calc(100vw - 116px) !important;
  }
}

.ant-form-item-label label {
  font-size: 16px !important;
  color: #595959 !important;
}

.nav.default-nav {
  display: none !important;
}

.ant-radio-wrapper span {
  font-size: 16px !important;
  color: #595959 !important;
}

.ant-table-row.ant-table-row-level-0:last-child>td,
.ant-table-cell {
  font-weight: 600;
}

.tabActive {
  width: 100%;

  .ant-tabs-nav-list {
    background-color: #F3F7FC;
  }

  .ant-tabs-tab-active {
    background-color: #004e7b !important;

    .ant-tabs-tab-btn {
      color: white !important;
      font-size: 16px;
    }
  }

  .ant-tabs-extra-content {
    .cursor-pointer {
      width: 25px;
    }
  }
}

.comingSoon {
  transform: rotate(45deg);
  position: absolute;
  right: -9px;
  top: 36px
}

.ant-input-number-input {
  text-align: end !important;
  padding: 0 !important;
  /* padding-right: 0 !important; */
  font-size: 18px !important;
  /* padding-bottom: 7px !important; */
}

@media (max-width: 575px) {
  .ant-input-number-input {
    font-size: 16px !important;
    padding: 0 0 7px 0 !important;
  }
}